import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Page } from 'components/Page';
import useAuth from 'auth/UseAuth';
import React, { useLayoutEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { DateAggregate } from 'historicCounts/DateAggregate';
import { DiseaseArea, GetDiseaseAreas } from 'data/DiseaseAreaData';
import { Biobank, GetBiobanks } from 'data/BiobankData';
import { ConsentEntitlement, GetConsentEntitlements } from 'data/ConsentEntitlementData';
import { GetBiobankPatientTotal, GetDiseaseAreaHistoricCounts, HistoricCount } from 'data/HistoricCountData';
import { HistoricCountFilters } from 'historicCounts/HistoricCountFilters';
import { HistoricDiseaseAreaCountBarChart } from 'historicCounts/HistoricDiseaseAreaCountBarChart';
import { GetSampleTypesInBiobanks, SampleTypeSelection } from 'data/SampleTypeData';
import 'historicCounts/HistoricCounts.css';
import { Box, Card } from '@mui/material';
import { LoadingIndicator } from '../components/LoadingIndicator';

type StatusType = 'Loading' | 'Complete' | 'InsufficientData';

export const DiseaseAreaMetricsPage = () => {
  const { accessToken } = useAuth();
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getUTCMonth() - 24);

  const [chartDataStatus, setChartDataStatus] = useState<StatusType>('Loading');

  // Filters
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateAggregate, setDateAggregate] = useState<DateAggregate>('month');
  const [diseaseAreas, setDiseaseAreas] = useState<DiseaseArea[]>([]);
  const [biobanks, setBiobanks] = useState<Biobank[]>([]);
  const [sampleTypes, setSampleTypes] = useState<SampleTypeSelection[]>([]);
  const [consents, setConsents] = useState<ConsentEntitlement[]>([]);

  // Totals and patient count data
  const [biobankTotal, setBiobankTotal] = useState<number>();
  const [biobankFilteredTotal, setBiobankFilteredTotal] = useState<number>();
  const [historicPatientCounts, setHistoricPatientCounts] = useState<HistoricCount[]>([]);

  useLayoutEffect(() => {
    async function getState() {
      if (accessToken) {
        let fetchedDiseaseAreas = (await GetDiseaseAreas(accessToken)).filter(
          disease => disease.availableToShowOnDashboard === true
        );
        fetchedDiseaseAreas.forEach(disease => (disease.selected = disease.showOnDashboardByDefault));
        setDiseaseAreas([...fetchedDiseaseAreas]);
        setBiobanks(await GetBiobanks(accessToken));
        setSampleTypes(await GetSampleTypesInBiobanks(accessToken));
        setConsents(await GetConsentEntitlements(accessToken));
        setBiobankTotal(await GetBiobankPatientTotal(accessToken));
      }
    }
    getState();
  }, [accessToken]);

  // Update total patient count and historic counts upon user filter selection
  useLayoutEffect(() => {
    async function getState() {
      if (
        accessToken &&
        diseaseAreas.length > 0 &&
        biobanks.length > 0 &&
        sampleTypes.length > 0 &&
        consents.length > 0
      ) {
        setChartDataStatus('Loading');
        let diseaseAreaIds = diseaseAreas
          .filter(disease => disease.selected === true)
          .map(disease => disease.diseaseAreaId);

        let biobankIds = biobanks.filter(biobank => biobank.selected === true).map(biobank => biobank.biobankId);

        let sampleTypeIds = sampleTypes
          .filter(sampleType => sampleType.selected === true)
          .map(sampleType => sampleType.sampleTypeId);

        setBiobankFilteredTotal(
          await GetBiobankPatientTotal(accessToken, startDate, endDate, diseaseAreaIds, biobankIds, sampleTypeIds)
        );

        const counts = await GetDiseaseAreaHistoricCounts(
          accessToken,
          startDate,
          endDate,
          dateAggregate,
          diseaseAreaIds,
          biobankIds,
          sampleTypeIds
        );
        setHistoricPatientCounts(orderBy(counts, ['date'], ['asc']));
        setChartDataStatus(counts.length > 0 ? 'Complete' : 'InsufficientData');
      }
    }
    getState();
  }, [accessToken, startDate, endDate, dateAggregate, biobanks, sampleTypes, consents, diseaseAreas]);

  return (
    <AuthorizedPage>
      <Page
        title='Historic Counts by Disease Area'
        subtitle={`Total Patients Biobanked: ${
          biobankTotal ? biobankTotal.toLocaleString() : ''
        } \nTotal Patients for Selected Filters: ${biobankFilteredTotal ? biobankFilteredTotal.toLocaleString() : ''}`}
      >
        <Box display='flex' flexDirection='row'>
          <Box width={320} mr={3}>
            <Card>
              <Box p={2}>
                <HistoricCountFilters
                  startDate={startDate}
                  endDate={endDate}
                  dateAggregate={dateAggregate}
                  diseaseAreas={diseaseAreas}
                  biobanks={biobanks}
                  sampleTypes={sampleTypes}
                  consentEntitlements={consents}
                  onDateAggregateSelect={setDateAggregate}
                  onStartDateSelect={setStartDate}
                  onEndDateSelect={setEndDate}
                  onDiseaseAreaSelect={setDiseaseAreas}
                  onBiobankSelect={setBiobanks}
                  onSampleTypeSelect={setSampleTypes}
                  onConsentSelect={setConsents}
                />
              </Box>
            </Card>
          </Box>
          <Box pb={3}>
            <Card sx={{ width: 1075, height: 700 }}>
              <Box p={4} justifyContent='center' alignItems='center'>
                {chartDataStatus === 'Loading' && <LoadingIndicator loadingState={{ status: 'Loading' }} />}
                {chartDataStatus === 'Complete' && (
                  <div>
                    <HistoricDiseaseAreaCountBarChart
                      diseaseAreas={diseaseAreas.filter(disease => disease.selected === true)}
                      historicCounts={historicPatientCounts}
                      dateAggregate={dateAggregate}
                      width={'1250px'}
                      height={'700px'}
                    />
                  </div>
                )}
                {chartDataStatus === 'InsufficientData' && (
                  <div className='historic-counts-loading'>No counts were found for selected filters.</div>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      </Page>
    </AuthorizedPage>
  );
};
