import React from 'react';
import { Box, IconButton, Stack, styled, Tooltip } from '@mui/material';
import { FlagTwoTone } from '@mui/icons-material';
import assertExhaustive from '../../utils/assertExhaustive';
import { useSetShowDataWithIssues, useShowDataWithIssues } from '../../stores/dataStore';
import { ShowDataWithIssues } from '../../stores/slices/showDataWithIssuesSlice';

const ShowDataWithIssuesButton: React.FC = () => {
  const showDataWithIssues = useShowDataWithIssues();
  const setShowDataWithIssues = useSetShowDataWithIssues();

  const [icon, title, next] = (() => {
    switch (showDataWithIssues) {
      case 'noIssues':
        return [<NoIssuesIcon />, 'Show all data', 'all' as ShowDataWithIssues];
      case 'onlyIssues':
        return [<OnlyIssuesIcon />, 'Show only data without issues', 'noIssues' as ShowDataWithIssues];
      case 'all':
        return [<AllIcon />, 'Show only data with issues', 'onlyIssues' as ShowDataWithIssues];
      default:
        assertExhaustive(showDataWithIssues);
        throw new Error('Unreachable');
    }
  })();

  return (
    <Tooltip arrow placement='top' title={title}>
      <IconButton sx={{ flex: 0 }} onClick={() => setShowDataWithIssues(next)}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const NoIssuesIcon = styled(FlagTwoTone)({
  color: 'rgb(44, 175, 254)', // Matches the Highcharts default series color
});

const OnlyIssuesIcon = styled(FlagTwoTone)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const AllIcon: React.FC = () => {
  return (
    <Box position='relative'>
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <NoIssuesIcon
          sx={{
            clipPath: 'polygon(0 0, 0% 100%, 100% 0)',
          }}
        />
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      >
        <OnlyIssuesIcon
          sx={{
            clipPath: 'polygon(100% 100%, 0 100%, 100% 0)',
          }}
        />
      </Stack>
    </Box>
  );
};

export default ShowDataWithIssuesButton;
