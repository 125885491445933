import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { DiseaseArea } from 'data/DiseaseAreaData';
import { AuthorizedSection } from '../../auth/AuthorizedSection';
import UpdateDiseaseAreaDialog from './UpdateDiseaseAreaDialog';

export interface Props {
  diseaseArea: DiseaseArea;
  onDiseaseAreaChange: Function;
}

export const DiseaseAreaGridCell = ({ diseaseArea, onDiseaseAreaChange }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = (diseaseAreaChanged: boolean) => {
    setOpen(false);
    if (diseaseAreaChanged) {
      onDiseaseAreaChange();
    }
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between' width='100%'>
        <Button
          onClick={handleButtonClick}
          sx={[
            {
              fontWeight: 'inherit',
              fontSize: 'inherit',
              justifyContent: 'left',
              padding: 0,

              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {diseaseArea.name}
        </Button>
        <AuthorizedSection hasGreenSideWriteAccess>
          <Button
            onClick={handleButtonClick}
            sx={[
              {
                fontWeight: 'inherit',
                justifyContent: 'right',
                padding: 0,
                margin: '0px 3px 0px 5px',

                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            ]}
          >
            <ModeEditOutlineOutlinedIcon />
          </Button>
        </AuthorizedSection>
      </Box>
      <AuthorizedSection hasGreenSideWriteAccess>
        <UpdateDiseaseAreaDialog open={open} diseaseArea={diseaseArea} onClose={handleClose} />
      </AuthorizedSection>
    </>
  );
};
