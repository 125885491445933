import React, { useEffect, useState } from 'react';
import {
  CreatePatientReview,
  PatientReview,
  PatientReviewScore,
  ReviewStatus,
} from '../../../../data/PatientReviewData';
import { Alert, Box, CircularProgress, Snackbar, Stack } from '@mui/material';
import ReviewNotes from './reviewNotes/ReviewNotes';
import ScorePicker from './scorePicker/ScorePicker';
import DecisionButtons, { DecisionAvailability } from './decisionButtons/DecisionButtons';
import { useApplyClientSideReview, usePatient, usePatientData, useResearchProjectId } from '../../stores/dataStore';
import useAuth from '../../../../auth/UseAuth';
import { initialLoadableData, LoadableData, trackLoading } from '../../utils/loadingUtils';

const ReviewGroup: React.FC<{
  onDoneReviewing: () => void;
}> = ({ onDoneReviewing }) => {
  const researchProjectId = useResearchProjectId();
  const patient = usePatient();
  const { data: patientData } = usePatientData();

  const { user, accessToken } = useAuth();

  //
  // decision components
  //
  const [score, setScore] = useState<PatientReviewScore | null>(null);
  const [notes, setNotes] = useState<string | null>(null);

  const unsaveableReason = score === null ? 'A score is needed to save a review' : undefined;
  const isReviewSavable = unsaveableReason === undefined;

  const [{ loading: reviewSaving, error: reviewSaveError }, setReviewSavingState] = useState<
    LoadableData<PatientReview>
  >(initialLoadableData());
  const [errorMessageShown, setErrorMessageShown] = useState(false);

  //
  // reload the existing review when the patient data changes
  //
  useEffect(() => {
    setScore(patientData?.review?.score ?? null);
    setNotes(patientData?.review?.notes ?? null);
  }, [patientData]);

  const undecidedAvailability: DecisionAvailability = {
    actionAvailable: true,
    tooltip: isReviewSavable ? undefined : 'Skip without saving review',
  };

  const rejectAvailability: DecisionAvailability = {
    actionAvailable: isReviewSavable,
    tooltip: isReviewSavable ? undefined : unsaveableReason,
  };

  const acceptAvailability: DecisionAvailability = {
    actionAvailable: isReviewSavable,
    tooltip: isReviewSavable ? undefined : unsaveableReason,
  };

  const applyClientSideReview = useApplyClientSideReview();

  const onDecisionSelected = (status: ReviewStatus['reviewStatusId']) => {
    if (researchProjectId === undefined || patient === undefined) {
      return;
    }

    if (isReviewSavable) {
      const review: PatientReview = {
        patientReviewId: '00000000-0000-0000-0000-000000000000', // Actual id will be assigned by the server
        reviewStatusId: status,
        researchProjectId: researchProjectId,
        token1: patient.token1,
        token2: patient.token2,
        reviewedBy: user?.name ?? '',
        reviewedAt: new Date(),
        notes: notes,
        score: score,
      };

      trackLoading(() => CreatePatientReview(review, accessToken), setReviewSavingState)
        .then(() => applyClientSideReview(review))
        .then(() => onDoneReviewing())
        .catch(_ => setErrorMessageShown(true));
    } else {
      onDoneReviewing();
    }
  };

  return (
    <Stack direction='column'>
      <ReviewNotes reviewNotes={notes} setReviewNotes={setNotes} />
      <Stack direction='row' spacing={2}>
        <Box sx={{ flex: 1 }}>
          <ScorePicker score={score} onScoreChange={setScore} />
        </Box>
        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2} sx={{ flex: 0 }}>
          <CircularProgress size={24} sx={{ visibility: reviewSaving ? 'visible' : 'hidden' }} />
          <DecisionButtons
            undecidedAvailability={undecidedAvailability}
            rejectAvailability={rejectAvailability}
            acceptAvailability={acceptAvailability}
            onDecisionSelected={onDecisionSelected}
          />
        </Stack>
      </Stack>

      <Snackbar open={errorMessageShown} autoHideDuration={5000} onClose={() => setErrorMessageShown(false)}>
        <Alert severity='error' onClose={() => setErrorMessageShown(false)}>
          Failed to save review: {reviewSaveError}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ReviewGroup;
