import { Button } from '@mui/material';
import { ResearchProjectDetails } from 'data/ResearchProjectData';
import { Link } from 'react-router-dom';

export interface ResearchProjectGridCellProps {
  researchProject: ResearchProjectDetails;
  url: string;
}

export const ResearchProjectGridCell = ({ researchProject, url }: ResearchProjectGridCellProps) => {
  return (
    <>
      <span>
        <Button
          component={Link}
          to={url}
          sx={[
            {
              fontSize: 'inherit',
              fontWeight: 'inherit',
              justifyContent: 'left',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
        >
          {researchProject.name}
        </Button>
      </span>
    </>
  );
};
