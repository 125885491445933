import React, { ComponentProps } from 'react';
import { Page } from 'components/Page';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthorizedSection } from './AuthorizedSection';

type Props = ComponentProps<typeof AuthorizedSection>;

export const AuthorizedPage = (props: Props) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <AuthorizedSection {...props} />;
  } else {
    return <Page title='You do not have access to this page'>{null}</Page>;
  }
};
