import { GridColDef, GridRowModel, GridRowsProp, useGridApiRef } from '@mui/x-data-grid-pro';
import { PersistableQualityCheckStatuses, SampleTrackingIdMapping } from '../../data/SampleTrackingData';
import {
  checkByFailedReason,
  errorMessage,
  labAssignedSampleId,
  newLabAssignedIdRequired,
  originalIdRequired,
  qualityCheckStatus,
  r1FastQLocation,
  r2FastQLocation,
  sampleIdentifier,
  warningMessage,
} from '../../util/Constants';
import { useEffect, useMemo, useState } from 'react';
import { LightTooltip } from '../../components/grid/cell/GridCellTooltip';
import { Typography } from '@mui/material';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import { CheckByConfiguration } from './CheckByConfiguration';
import { CheckByFailedReason } from '../../data/ReferenceData';
import { find } from 'lodash';

export interface SampleTrackingIdMappingGridProps {
  data: ReadonlyArray<SampleTrackingIdMapping>;
  onRowUpdate: (rowIndex: string, row: Row) => void;
  config: CheckByConfiguration;
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>;
}

type Row = SampleTrackingIdMapping & {
  id: string;
};

export const SampleTrackingIdMappingGrid = ({
  data,
  onRowUpdate,
  config,
  checkByFailedReasons,
}: SampleTrackingIdMappingGridProps) => {
  const apiRef = useGridApiRef();
  const columns = useColumns(config, checkByFailedReasons);
  const [rows, setRows] = useState<GridRowsProp<Row>>([]);

  useEffect(() => {
    if (data) {
      setRows(getRows(data));
    }
  }, [data]);

  function getRows(tableData: ReadonlyArray<SampleTrackingIdMapping>) {
    return tableData.map(r => {
      return { id: r.index, ...r };
    });
  }

  return (
    <CompactGridWrapper
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      disableColumnFilter={true}
      disableColumnMenu={true}
      initialState={{
        sorting: {
          sortModel: [
            { field: errorMessage, sort: 'asc' },
            { field: warningMessage, sort: 'asc' },
          ],
        },
      }}
      processRowUpdate={(newRow: GridRowModel<Row>) => {
        onRowUpdate(newRow.index, newRow);
        return newRow;
      }}
      editMode={'row'}
      disableRowSelectionOnClick
      columnsToAutoSize={columns.map(c => c.field)}
    />
  );
};

const useColumns = (
  checkByConfiguration: CheckByConfiguration,
  checkByFailedReasons: ReadonlyArray<CheckByFailedReason>
): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    const requireLabAssignedIdColumns: GridColDef[] = checkByConfiguration.requireLabAssignedId
      ? [
          {
            field: labAssignedSampleId,
            headerName: t(newLabAssignedIdRequired),
            headerAlign: 'left',
            align: 'left',
            minWidth: 250,
            editable: true,
          },
        ]
      : [];

    const sequencingColumns: GridColDef[] =
      checkByConfiguration.configurationType === 'Sequencing'
        ? [
            {
              field: r1FastQLocation,
              headerName: t(r1FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 1,
            },
            {
              field: r2FastQLocation,
              headerName: t(r2FastQLocation),
              headerAlign: 'left',
              align: 'left',
              minWidth: 200,
              flex: 1,
            },
          ]
        : [];

    const supportsCheckByIssueColumns: GridColDef[] = checkByConfiguration.supportsCheckByFailedReason
      ? [
          {
            field: checkByFailedReason,
            headerName: t(checkByFailedReason),
            headerAlign: 'left',
            align: 'left',
            minWidth: 200,
            flex: 1,
            type: 'singleSelect',
            valueOptions: checkByFailedReasons.map(i => i.name),
            getOptionLabel: (value: any) =>
              value && find(checkByFailedReasons, i => i.name?.toLowerCase() === value?.toLowerCase())?.displayName,
            valueFormatter: ({ value }) =>
              value && find(checkByFailedReasons, i => i.name?.toLowerCase() === value?.toLowerCase())?.displayName,
            editable: true,
          },
        ]
      : [];

    const messageColumns: GridColDef[] = [
      {
        field: errorMessage,
        headerName: t(errorMessage),
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
        renderCell: ({ value }) => {
          return (
            (value ?? []).length > 0 && (
              <LightTooltip title={value.map((s: any) => t(s)).join('\n') ?? ''}>
                <Typography variant={'h5'}>{value.length} Error(s)</Typography>
              </LightTooltip>
            )
          );
        },
      },
      {
        field: warningMessage,
        headerName: t(warningMessage),
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
        renderCell: ({ value }) => {
          return (
            (value ?? []).length > 0 && (
              <LightTooltip title={value.map((s: any) => t(s)).join('\n') ?? ''}>
                <Typography variant={'h5'}>{value.length} Warning(s)</Typography>
              </LightTooltip>
            )
          );
        },
      },
    ];

    const columnOverrides = [
      {
        field: sampleIdentifier,
        headerName: t(originalIdRequired),
        headerAlign: 'left',
        align: 'left',
        minWidth: 300,
        editable: true,
      },
      ...requireLabAssignedIdColumns,
      {
        field: qualityCheckStatus,
        headerName: t(qualityCheckStatus),
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
        type: 'singleSelect',
        valueOptions: PersistableQualityCheckStatuses.map(value => t(value)),
        editable: true,
      },
      ...supportsCheckByIssueColumns,
      ...sequencingColumns,
      ...messageColumns,
    ];

    const columns: GridColDef[] = (checkByConfiguration.columns ?? []).map(column => {
      const override = find(columnOverrides, i => i.field === column);

      return override !== undefined
        ? override
        : {
            field: column,
            headerName: t(column as any),
            headerAlign: 'left',
            align: 'left',
            minWidth: 125,
            editable: true,
            valueGetter: ({ row }) => row.dynamicData && row.dynamicData[column],
            valueSetter: params => ({
              ...params.row,
              dynamicData: { ...params.row.dynamicData, [column]: params.value },
              [column]: params.value,
            }),
          };
    }) as GridColDef[];

    if (columns.length > 0) {
      return [...columns, ...messageColumns];
    }

    return columnOverrides;
  }, [t, checkByConfiguration, checkByFailedReasons]) as GridColDef[];
};
