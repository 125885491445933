import { GridColDef, GridPaginationModel, GridRowsProp, GridSortModel } from '@mui/x-data-grid-pro';
import useAuth from 'auth/UseAuth';
import { renderCellTooltip } from 'components/grid/cell/GridCellTooltip';
import {
  GetResearchProjectPatientCount,
  GetResearchProjectPatients,
  ResearchProjectCohortRecordOrderBy,
  ResearchProjectPatient,
} from 'data/ResearchProjectCohortData';
import { useEffect, useMemo, useState } from 'react';
import { renderSampleCommentCellTooltip } from 'components/grid/cell/SampleCommentGridCellTooltip';
import { ConvertToCapitalizedDashSeparated } from 'data/conversions/StatusEnumConversions';
import { renderGuidCellTooltip } from '../../components/grid/cell/GuidGridCellTooltip';
import useMemoTranslation from 'hooks/UseMemoTranslation';
import {
  biobank,
  biobanks,
  ethnicity,
  gender,
  icd9AndIcd10Codes,
  lab,
  labs,
  latestSampleCollectionDate,
  numOtherProjects,
  numSamplesSelected,
  patientId,
  patientNotes,
  patientSelectionStatus,
  race,
  reviewScore,
  reviewStatus,
  sampleComment,
  sampleComments,
  sampleCount,
  sampleType,
  sampleTypes,
  snomedConceptIdsWithCtValue,
  snomedTerms,
  yearOfBirth,
} from 'util/Constants';
import { ConvertToSingleValue } from 'data/conversions/StringConversions';
import { PatientRecordOptions, RecordSorting } from 'data/PatientRecordOptions';
import { LoadingState, LoadState } from 'components/LoadingStateUtil';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';

export interface ResearchProjectCohortGridProps {
  researchProjectId: string;
  autoHeight: boolean;
}

export const ResearchProjectCohortGrid = ({ researchProjectId, autoHeight }: ResearchProjectCohortGridProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [totalPatients, setTotalPatients] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: patientSelectionStatus, sort: 'asc' }]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ pageSize: 100, page: 0 });
  const [researchProjectPatients, setResearchProjectPatients] = useState<ResearchProjectPatient[]>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const columns = useColumns();

  useEffect(() => {
    async function getState() {
      if (accessToken && researchProjectId !== undefined) {
        setTotalPatients(await GetResearchProjectPatientCount(researchProjectId, accessToken));
      }
    }

    getState();
  }, [accessToken, researchProjectId]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (accessToken && researchProjectId !== undefined && totalPatients > 0) {
        let sorting = sortModel.map(s => {
          return { orderBy: s.field, sortOrder: s.sort } as RecordSorting<ResearchProjectCohortRecordOrderBy>;
        });

        let patientRecordOptions: PatientRecordOptions<ResearchProjectCohortRecordOrderBy> = {
          timeInterval: 'AllTime',
          sorting: sorting,
          paging: {
            pageSize: paginationModel.pageSize,
            pageStart: paginationModel.page,
          },
          decrypted: false,
        };

        let researchProjectPatients = await GetResearchProjectPatients(
          researchProjectId,
          patientRecordOptions,
          accessToken
        );

        setResearchProjectPatients([...researchProjectPatients]);
      }
    });
  }, [accessToken, totalPatients, researchProjectId, sortModel, paginationModel]);

  useEffect(() => {
    if (researchProjectPatients) {
      setRows(getRows(researchProjectPatients));
    }
  }, [researchProjectPatients]);

  return (
    <CompactGridWrapper
      loadingState={loadingState}
      rows={rows}
      columns={columns}
      initialState={{ pinnedColumns: { left: [patientId, patientSelectionStatus] } }}
      autoHeight={autoHeight}
      disableColumnFilter={true}
      pagination={true}
      paginationMode='server'
      paginationModel={paginationModel}
      onPaginationModelChange={newPaginationModel => {
        setPaginationModel(newPaginationModel);
      }}
      rowCount={totalPatients}
      pageSizeOptions={[100, 200, 500]}
      sortingMode='server'
      sortModel={sortModel}
      onSortModelChange={newSortModel => {
        if (newSortModel?.length > 0) {
          setSortModel(newSortModel);
        }
      }}
      hideFooterSelectedRowCount={true}
    />
  );
};

const useColumns = (): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: patientId,
        headerName: t(patientId),
        width: 130,
        renderCell: renderGuidCellTooltip,
      },
      {
        field: patientSelectionStatus,
        headerName: t(patientSelectionStatus),
        width: 220,
      },
      {
        field: numOtherProjects,
        headerName: t(numOtherProjects),
        headerAlign: 'center',
        align: 'center',
        width: 250,
      },
      {
        field: sampleCount,
        headerName: t(sampleCount),
        headerAlign: 'center',
        align: 'center',
        width: 150,
      },
      {
        field: numSamplesSelected,
        headerName: t(numSamplesSelected),
        headerAlign: 'center',
        align: 'center',
        width: 250,
      },
      {
        field: biobanks,
        headerName: t(biobank),
        width: 230,
      },
      {
        field: labs,
        headerName: t(lab),
        width: 230,
      },
      {
        field: sampleTypes,
        headerName: t(sampleType),
        width: 200,
        renderCell: renderCellTooltip,
      },
      {
        field: latestSampleCollectionDate,
        headerName: t(latestSampleCollectionDate),
        headerAlign: 'center',
        align: 'center',
        width: 230,
      },
      {
        field: gender,
        headerName: t(gender),
        width: 100,
      },
      {
        field: yearOfBirth,
        headerName: t(yearOfBirth),
        headerAlign: 'center',
        align: 'center',
        width: 120,
      },
      {
        field: race,
        headerName: t(race),
        width: 170,
      },
      {
        field: ethnicity,
        headerName: t(ethnicity),
        width: 170,
      },
      {
        field: snomedConceptIdsWithCtValue,
        headerName: t(snomedConceptIdsWithCtValue),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: snomedTerms,
        headerName: t(snomedTerms),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: icd9AndIcd10Codes,
        headerName: t(icd9AndIcd10Codes),
        width: 230,
        renderCell: renderCellTooltip,
      },
      {
        field: sampleComments,
        headerName: t(sampleComment),
        width: 230,
        renderCell: renderSampleCommentCellTooltip,
      },
      {
        field: patientNotes,
        headerName: t(patientNotes),
        width: 200,
        renderCell: renderCellTooltip,
      },
      {
        field: reviewStatus,
        headerName: t(reviewStatus),
        width: 120,
        renderCell: renderCellTooltip,
      },
      {
        field: reviewScore,
        headerName: t(reviewScore),
        width: 120,
        headerAlign: 'center',
        align: 'center',
        renderCell: renderCellTooltip,
      },
    ];
  }, [t]);
};

function getRows(researchProjectPatients: ResearchProjectPatient[]) {
  const rows: any[] = [];

  researchProjectPatients.forEach((rpp, index) => {
    rows.push({
      id: index++,
      patientId: rpp.patientId,
      patientSelectionStatus: ConvertToCapitalizedDashSeparated(rpp.patientSelectionStatus),
      numOtherProjects: rpp.numOtherProjects,
      sampleCount: rpp.sampleCount,
      numSamplesSelected: rpp.numSamplesSelected,
      biobanks: ConvertToSingleValue(rpp.sampleCount, rpp.biobanks),
      labs: ConvertToSingleValue(rpp.sampleCount, rpp.labs),
      sampleTypes: ConvertToSingleValue(rpp.sampleCount, rpp.sampleTypes),
      latestSampleCollectionDate: dateToString(new Date(rpp.latestSampleCollectionDate)),
      gender: rpp.gender,
      yearOfBirth: rpp.yearOfBirth,
      race: rpp.race,
      ethnicity: rpp.ethnicity,
      snomedConceptIdsWithCtValue: rpp.snomedConceptIds.sort().join(', '),
      snomedTerms: rpp.snomedTerms.sort().join(', '),
      icd9AndIcd10Codes: rpp.icd9AndIcd10Codes.sort().join(', '),
      sampleComments: ConvertToSingleValue(rpp.sampleCount, rpp.sampleComments),
      patientNotes: rpp.notes,
      reviewStatus: rpp.reviewStatus,
      reviewScore: rpp.reviewScore,
    });
  });
  return rows;
}

function dateToString(date: Date) {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
