import { ExhaustionApproach, SampleActionOnCheckBy, TransitionEnum } from './ReferenceData';
import { BaseGet, BasePostVoid } from './BaseData';
import { appSettings } from '../AppSettings';

export interface ConfiguredTransition {
  configuredTransitionId: string;
  transitionEnum: TransitionEnum;
  displayName: string;
  researchProjectId: string;
  transitionOrder: number;
  canBeDeleted: boolean;
  canBeEdited: boolean;
  canDisplayNameBeEdited: boolean;
  exhaustionApproach: ExhaustionApproach;
  sampleActionOnCheckBy: SampleActionOnCheckBy;
  enableCheckByFailedReason: boolean;
  enableSampleExhaustion: boolean;
  isEndState: boolean;
}

export interface ProcessConfiguredTransition {
  researchProjectId: string;
  configuredTransitionId?: string;
  transitionOrder: number;
  transitionEnumId: string;
  displayName: string;
  isDeleted: boolean;
  enableCheckByFailedReason?: boolean;
  enableSampleExhaustion?: boolean;
  exhaustionApproach?: string;
  sampleActionOnCheckBy?: string;
  isEndState: boolean;
}

export async function GetResearchProjectConfiguredTransitions(
  researchProjectId: string,
  accessToken?: string
): Promise<ReadonlyArray<ConfiguredTransition>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/ConfiguredTransition/${researchProjectId}`, accessToken);
}

export async function ProcessConfiguredTransitions(
  data: ReadonlyArray<ProcessConfiguredTransition>,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/ConfiguredTransition/process`, data, accessToken);
}

export async function SetConfiguredTransitionsToDefaultAsync(
  researchProjectId: string,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/ConfiguredTransition/setToDefault/${researchProjectId}`,
    {},
    accessToken
  );
}
