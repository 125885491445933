export const actualCohortSize = 'actualCohortSize';
export const all = 'all';
export const bbIdOrForeignHash = 'bbIdOrForeignHash';
export const bbid = 'bbid';
export const bbids = 'bbids';
export const biobank = 'biobank';
export const biobankId = 'biobankId';
export const biobanks = 'biobanks';
export const cancel = 'cancel';
export const checkByFailedReason = 'checkByFailedReason';
export const collectionDate = 'collectionDate';
export const compactGridHeaderClassName = 'compact-grid--header';
export const computedValues = 'computedValues';
export const concentration = 'concentration';
export const copyBtn = 'copyBtn';
export const createdAt = 'createdAt';
export const createdBy = 'createdBy';
export const currentStage = 'currentStage';
export const customer = 'customer';
export const dashDash = '--';
export const dashboard = 'dashboard';
export const dataClassName = 'dataClassName';
export const dataSourceName = 'dataSourceName';
export const deidentity = 'deidentity';
export const deletePathogenGroup = 'deletePathogenGroup';
export const deliveredCount = 'deliveredCount';
export const depth = 'depth';
export const depths = 'depths';
export const description = 'description';
export const destinationStorageTemperatureId = 'destinationStorageTemperatureId';
export const diseaseArea = 'diseaseArea';
export const diseaseAreaCountsFileName = 'diseaseAreaCountsFileName';
export const downloadFile = 'downloadFile';
export const editResearchProject = 'editResearchProject';
export const enteredAt = 'enteredAt';
export const entityName = 'entityName';
export const errorMessage = 'errorMessage';
export const ethnicity = 'ethnicity';
export const executedAt = 'executedAt';
export const executionOrder = 'executionOrder';
export const executedPipelineHistoryId = 'executedPipelineHistoryId';
export const executedQueryHistoryId = 'executedQueryHistoryId';
export const executionDurationMs = 'executionDurationMs';
export const existingSequenceRun = 'existingSequenceRun';
export const exitedAt = 'exitedAt';
export const expectedCohortSize = 'expectedCohortSize';
export const extractionCount = 'extractionCount';
export const extractionKitId = 'extractionKitId';
export const extractionPlatformId = 'extractionPlatformId';
export const extractionType = 'extractionType';
export const failedCount = 'failedCount';
export const fastqLocation = 'fastqLocation';
export const field = 'field';
export const file = 'file';
export const flaggingName = 'flagging';
export const foreignHash = 'foreignHash';
export const foreignHashes = 'foreignHashes';
export const gender = 'gender';
export const hasExistingSequenceRun = 'hasExistingSequenceRun';
export const icd10Codes = 'icd10Codes';
export const icd9AndIcd10Codes = 'icd9AndIcd10Codes';
export const id = 'id';
export const informaticsCount = 'informaticsCount';
export const informaticsRunStatus = 'informaticsRunStatus';
export const inputForeignHash = 'inputForeignHash';
export const inputId = 'inputId';
export const inputLabAssignedId = 'inputLabAssignedId';
export const inputSampleBbid = 'inputSampleBbid';
export const inputSampleId = 'inputSampleId';
export const installedOn = 'installedOn';
export const instrumentName = 'instrumentName';
export const invalidSampleIdsFileName = 'invalidSampleIdsFileName';
export const invalidSampleIdentifier = 'invalidSampleIdentifier';
export const isDeliveryCandidate = 'isDeliveryCandidate';
export const issueName = 'issueName';
export const key = 'key';
export const lab = 'lab';
export const labAssignedSampleId = 'labAssignedSampleId';
export const labId = 'labId';
export const labs = 'labs';
export const lastUpdated = 'lastUpdated';
export const latestSampleCollectionDate = 'latestSampleCollectionDate';
export const libraryKitId = 'libraryKitId';
export const libraryKitName = 'libraryKitName';
export const libraryPrepCount = 'libraryPrepCount';
export const location = 'location';
export const mRawTotalSeqs = 'mRawTotalSeqs';
export const mReads = 'mReads';
export const mappedFromCurrentTransition = 'mappedFromCurrentTransition';
export const mappedFromSampleIdentifier = 'mappedFromSampleIdentifier';
export const mappedToSampleIdentifier = 'mappedToSampleIdentifier';
export const maxDepth = 'maxDepth';
export const meanDepth = 'meanDepth';
export const medianCoverage = 'medianCoverage';
export const minimumSampleVolume = 'minimumSampleVolume';
export const minimumSampleVolumeUnitId = 'minimumSampleVolumeUnitId';
export const mondayLink = 'mondayLink';
export const multiQc = 'multiQc';
export const nameField = 'name';
export const nameRequired = 'nameRequired';
export const needsReview = 'needsReview';
export const newForeignHash = 'newForeignHash';
export const newId = 'newId';
export const newLabAssignedId = 'newLabAssignedId';
export const newLabAssignedIdRequired = 'newLabAssignedIdRequired';
export const newPathogen = 'newPathogen';
export const newSampleBbid = 'newSampleBbid';
export const newSampleId = 'newSampleId';
export const numOtherProjects = 'numOtherProjects';
export const numSamplesSelected = 'numSamplesSelected';
export const opaqueIdentifier = 'opaqueIdentifier';
export const orderedAt = 'orderedAt';
export const organizationId = 'organizationId';
export const originalForeignHash = 'originalForeignHash';
export const originalId = 'originalId';
export const originalIdRequired = 'originalIdRequired';
export const originalLabAssignedId = 'originalLabAssignedId';
export const originalSampleBbid = 'originalSampleBbid';
export const outputOf = 'outputOf';
export const passedCount = 'passedCount';
export const passedQualityCheck = 'passedQualityCheck';
export const pathogenCountsFileName = 'pathogenCountsFileName';
export const pathogenGroup = 'pathogenGroup';
export const patientId = 'patientId';
export const patientNotes = 'patientNotes';
export const patientProjectCreatedAt = 'patientProjectCreatedAt';
export const patientSelectionStatus = 'patientSelectionStatus';
export const percentDuplication = 'percentDuplication';
export const percentDups = 'percentDups';
export const percentGcContent = 'percentGcContent';
export const pipeline = 'pipeline';
export const pipelineId = 'pipelineId';
export const positionId = 'positionId';
export const previousQcUpdated = 'previousQcUpdated';
export const primaryShippingContainerId = 'primaryShippingContainerId';
export const project = 'project';
export const qualityCheckStatus = 'qualityCheckStatus';
export const query = 'query';
export const queryCondition = 'queryCondition';
export const queryGroupBy = 'queryGroupBy';
export const queryHistory = 'queryHistory';
export const queryJoin = 'queryJoin';
export const r1FastQLocation = 'r1FastQLocation';
export const r1FastQLocationRecommendation = 'r1FastQLocationRecommendation';
export const r2FastQLocation = 'r2FastQLocation';
export const r2FastQLocationRecommendation = 'r2FastQLocationRecommendation';
export const race = 'race';
export const rackId = 'rackId';
export const rawTotalSequences = 'rawTotalSequences';
export const reBiobankingCount = 'reBiobankingCount';
export const readsAligned = 'readsAligned';
export const readsMapped = 'readsMapped';
export const readsMappedPercent = 'readsMappedPercent';
export const readsProperlyPairedPercent = 'readsProperlyPairedPercent';
export const readyForDeliveryCount = 'readyForDeliveryCount';
export const receiverOrganizationId = 'receiverOrganizationId';
export const receivingCarrierId = 'receivingCarrierId';
export const receivingCount = 'receivingCount';
export const receivingDate = 'receivingDate';
export const recordQc = 'recordQc';
export const referenceType = 'referenceType';
export const referenceTypeId = 'referenceTypeId';
export const refreshedAt = 'refreshedAt';
export const researchProject = 'researchProject';
export const reviewStatus = 'reviewStatus';
export const reviewScore = 'reviewScore';
export const rowsAffected = 'rowsAffected';
export const salesforceLink = 'salesforceLink';
export const sampleAvailabilityTypeId = 'sampleAvailabilityTypeId';
export const sampleBbid = 'sampleBbid';
export const sampleComment = 'sampleComment';
export const sampleComments = 'sampleComments';
export const sampleCount = 'sampleCount';
export const sampleGroup = 'sampleGroup';
export const sampleId = 'sampleId';
export const sampleIdentifier = 'sampleIdentifier';
export const sampleNotes = 'sampleNotes';
export const sampleProjectCreatedAt = 'sampleProjectCreatedAt';
export const sampleTrackingQualityCheckUndoDialog = 'sampleTrackingQualityCheckUndoDialog';
export const sampleTrackingTransitionFileDialog = 'sampleTrackingTransitionFileDialog';
export const sampleTrackingTransitionUndoDialog = 'sampleTrackingTransitionUndoDialog';
export const sampleType = 'sampleType';
export const sampleTypeId = 'sampleTypeId';
export const sampleTypes = 'sampleTypes';
export const sampleVolume = 'sampleVolume';
export const seenBases = 'seenBases';
export const selectedSampleCount = 'selectedSampleCount';
export const selectionStatus = 'selectionStatus';
export const senderOrganizationId = 'senderOrganizationId';
export const sequenceId = 'sequenceId';
export const sequenceRunId = 'sequenceRunId';
export const sequenceType = 'sequenceType';
export const sequencingCount = 'sequencingCount';
export const sequencingExpectedCoverage = 'sequencingExpectedCoverage';
export const sequencingLab = 'sequencingLab';
export const sequencingLabId = 'sequencingLabId';
export const sequencingLabName = 'sequencingLabName';
export const sequencingPlatformId = 'sequencingPlatformId';
export const sequencingPlatformName = 'sequencingPlatformName';
export const shipByDate = 'shipByDate';
export const shippingCarrierId = 'shippingCarrierId';
export const shippingCount = 'shippingCount';
export const shippingStorageTemperatureId = 'shippingStorageTemperatureId';
export const snomedClinicalTermsRequired = 'snomedClinicalTermsRequired';
export const snomedConceptIds = 'snomedConceptIds';
export const snomedConceptIdsWithCtValue = 'snomedConceptIdsWithCtValue';
export const snomedTerms = 'snomedTerms';
export const stGridPatientDeidentity = 'patient.deidentity';
export const stGridPatientId = 'patient.patientid';
export const stGridPatientSourceKey = 'patient.sourcekey';
export const stGridResearchProjectSampleSelectionStatus = 'researchproject.researchprojectsample:selectionstatus';
export const stGridRootSampleBbid = 'rootsample.samplebbid';
export const stGridRootSampleForeignHash = 'rootsample.foreignhash';
export const stGridRootSampleSampleId = 'rootsample.sampleid';
export const stGridSampleAvailability = 'sample.sampleavailabilitytypeid';
export const stGridSampleBbid = 'sample.samplebbid';
export const stGridSampleBioBankId = 'sample.biobankid';
export const stGridSampleCollectionDate = 'sample.collectionDate';
export const stGridSampleForeignHash = 'sample.foreignhash';
export const stGridSampleId = 'sample.sampleid';
export const stGridSampleLabId = 'sample.labid';
export const stGridSamplePatientId = 'sample.patientid';
export const stGridSampleSampleTypeId = 'sample.sampletypeid';
export const stGridTransitionSampleTransitionId = 'transitionsample.transitionid';
export const state = 'state';
export const statusField = 'status';
export const stepId = 'stepId';
export const stepName = 'stepName';
export const stepTypeName = 'stepTypeName';
export const submit = 'submit';
export const subsettingCount = 'subsettingCount';
export const subsettingType = 'subsettingType';
export const supplementalFileType = 'supplementalFileType';
export const tissueType = 'tissueType';
export const tissueTypeName = 'tissueTypeName';
export const total = 'total';
export const totalBases = 'totalBases';
export const totalReads = 'totalReads';
export const trackingNumber = 'trackingNumber';
export const transition = 'transition';
export const transitionId = 'transitionId';
export const transitionQualityCheckGroupId = 'transitionQualityCheckGroupId';
export const trimmedR1PercentGc = 'trimmedR1PercentGc';
export const trimmedR2PercentGc = 'trimmedR2PercentGc';
export const twoSixtyTwoEightyRatio = 'twoSixtyTwoEightyRatio';
export const uniquelyMapped = 'uniquelyMapped';
export const uniquelyMappedPercent = 'uniquelyMappedPercent';
export const updateableFastq = 'updateableFastq';
export const updatedBy = 'updatedBy';
export const value = 'value';
export const version = 'version';
export const volume = 'volume';
export const wallClock = 'wallClock';
export const warningMessage = 'warningMessage';
export const yearOfBirth = 'yearOfBirth';
export const yieldField = 'yield';
