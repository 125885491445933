import qs from 'qs';
import { appSettings } from '../AppSettings';
import { BaseDeleteVoid, BaseGet, BaseGetWithParams, BasePost, BasePostVoid, BasePut } from './BaseData';
import { Migration } from './MigrationsData';

export type SuperDuperFiestaPipeline = {
  pipelineId: string;
  pipelineStatusId?: string;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaEntity = {
  entityId: string;
  displayName: string;
  name: string;
  tablePrefix: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaExecutedPipelineHistory = {
  executedPipelineHistoryId: string;
  pipelineId: string;
  executedAt: string;
  executionDurationMs: number;
  state: string;
};

export type SuperDuperFiestaStage = {
  stageId: string;
  name: string;
  displayName: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
};

export type SuperDuperFiestaStep = {
  stepId: string;
  stepTypeId: string;
  entityId: string;
  dataSourceId?: string;
  name: string;
  description?: string;
  needsReview: boolean;
  queryCondition?: string;
  queryJoin?: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  dataClassId?: number;
};

export type SdfStepType = {
  stepTypeId: string;
  name: string;
  displayName: string;
  description: string | null;
  needsReview: boolean;
};

export type SdfIssue = {
  issueId?: number;
  name: string;
};

export type SdfStepUpdateValue = {
  field: string | undefined;
  value: string | undefined;
};

export type SuperDuperFiestaPipelineConfig = {
  entities: ReadonlyArray<string>;
  runSubsequentStages: boolean;
  startAtStage?: string;
  startAtStep?: string;
};

export type SuperDuperFiestaExecutedQueryHistory = {
  executedQueryHistoryId: string;
  executedPipelineHistoryId: string;
  query: string;
  stepId?: string;
  executedAt: string;
  executionDurationMs?: number;
  state: string;
  rowsAffected?: number;
};

export type SuperDuperFiestaDataSource = {
  dataSourceId: string;
  name: string;
  displayName: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SuperDuperFiestaDataClass = {
  dataClassId?: number;
  name: string;
  entityId: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
};

export type SuperDuperFiestaPipelineReviewIssues = SdfIssue & { issueCount: number };

export type SuperDuperFiestaPipelineReviewTypes = {
  dataClassId: number;
};

export type PipelineReviewConfig = {
  dataClassId?: number;
  dataSourceId?: string;
  entity: string;
  stage?: string;
  pageNumber?: string;
  pageSize?: string;
  selectedIssueId?: string;
  sortBy?: string[];
  logicOperator?: string;
  filterBy?: FilterParamsConfig;
};

export type FilterParamsConfig = {
  [key: string]: string | string[];
};

export type PipelineRulesConfig = {
  pipelineId: string;
  stageId: string;
  entityId?: string;
};

export type PipelineRule = {
  id: number;
  stepId: string;
  executionOrder: number;
  executionOrderDisplay: number;
  name: string;
  description?: string | null;
  stepTypeName: string | null;
  stepTypeId?: string | null;
  entityName: string;
  entityId?: string | null;
  dataSourceName?: string | null;
  dataSourceId?: string | null;
  dataClassName?: string | null;
  dataClassId?: number | null;
  issueName?: string | null;
  issueId?: number | null;
  needsReview: boolean;
  active: boolean;
  queryCondition?: string | null;
  queryJoin?: string | null;
  queryGroupBy?: string | null;
  field?: string;
  value?: string;
  rowsAffected?: number;
  executionDurationMs?: number;
  state?: string;
  executedAt?: string;
  createdBy?: string | null;
  updatedBy?: string | null;
  createdAt?: string;
  hierarchy?: string[];
  stepUpdateValueId?: string;
  stepUpdateValues?: SdfStepUpdateValue[] | null;
  stageId?: string;
  pipelineId?: string;
  previousStepId?: string | null;
};

export type PipelineFiltersConfig = {
  includePipelines: boolean;
  includeStages: boolean;
  includeEntities: boolean;
  includeStepTypes: boolean;
  includeIssues: boolean;
  includeDataClasses: boolean;
  includeDataSources: boolean;
};

export type PipelineFilters = {
  pipelines?: SuperDuperFiestaPipeline[];
  stages?: SuperDuperFiestaStage[];
  entities?: SuperDuperFiestaEntity[];
  stepTypes?: SdfStepType[];
  issues?: SdfIssue[];
  dataClasses?: SuperDuperFiestaDataClass[];
  dataSources?: SuperDuperFiestaDataSource[];
};

export async function GetPipelines(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaPipeline>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Pipeline`, accessToken);
}

export async function GetEntities(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaEntity>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Entity`, accessToken);
}

export async function GetDataSources(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaDataSource>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/DataSource`, accessToken);
}

export async function GetDataClasses(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaDataClass>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/DataClass`, accessToken);
}

export async function CreateDataClass(
  data: SuperDuperFiestaDataClass,
  accessToken?: string
): Promise<SuperDuperFiestaDataClass> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/DataClass`, data, accessToken);
}

export async function CreateIssue(data: SdfIssue, accessToken?: string): Promise<SdfIssue> {
  return await BasePost(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Issue`, data, accessToken);
}

export async function GetPipelineExecutionHistory(
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaExecutedPipelineHistory>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/History`, accessToken);
}

export async function GetQueryExecutionHistory(
  executedPipelineHistoryId: string,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaExecutedQueryHistory>> {
  return await BaseGet(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/QueryHistory/${executedPipelineHistoryId}`,
    accessToken
  );
}

export async function GetStages(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaStage>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Stage`, accessToken);
}

export async function GetSteps(accessToken?: string): Promise<ReadonlyArray<SuperDuperFiestaStep>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Step`, accessToken);
}

export async function ExecutePipeline(
  pipelineId: string,
  config: SuperDuperFiestaPipelineConfig,
  accessToken?: string
): Promise<void> {
  await BasePostVoid(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineExecution/Execute/PipelineId/${pipelineId}`,
    config,
    accessToken
  );
}

export async function GetPipelineReview(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<object>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview`,
    params,
    accessToken
  );
}

export async function GetPipelineReviewIssues(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaPipelineReviewIssues>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview/PipelineReviewIssues`,
    params,
    accessToken
  );
}

export async function GetPipelineReviewTypes(
  queryParams: PipelineReviewConfig,
  accessToken?: string
): Promise<ReadonlyArray<SuperDuperFiestaPipelineReviewTypes>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineReview/PipelineReviewTypes`,
    params,
    accessToken
  );
}

export async function GetPipelineRules(
  queryParams: PipelineRulesConfig,
  accessToken?: string
): Promise<ReadonlyArray<PipelineRule>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineRules`,
    params,
    accessToken
  );
}

export async function CreatePipelineRules(
  data: PipelineRule[],
  queryParams: PipelineRulesConfig,
  accessToken?: string
): Promise<void> {
  const params = qs.stringify(queryParams);
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineRules?${params}`, data, accessToken);
}

export async function UpdatePipelineRule(
  stepId: string,
  data: PipelineRule,
  queryParams: PipelineRulesConfig,
  accessToken?: string
): Promise<object> {
  const params = qs.stringify(queryParams);
  return await BasePut(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineRules/${stepId}?${params}`,
    data,
    accessToken
  );
}

export async function DeletePipelineRule(stepId: string, accessToken?: string): Promise<void> {
  await BaseDeleteVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineRules/${stepId}`, accessToken);
}

export async function GetPipelineFilters(
  queryParams: PipelineFiltersConfig,
  accessToken?: string
): Promise<Readonly<PipelineFilters>> {
  const params = qs.stringify(queryParams);

  return await BaseGetWithParams(
    `${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/PipelineFilters`,
    params,
    accessToken
  );
}

export async function GetMigrations(accessToken?: string): Promise<ReadonlyArray<Migration>> {
  return await BaseGet(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations`, accessToken);
}
export async function ExecuteMigrations(accessToken?: string): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations`, undefined, accessToken);
}

export async function RepairMigrations(accessToken?: string): Promise<void> {
  await BasePostVoid(`${appSettings.api.endpoint}/api/v2/SuperDuperFiesta/Migrations/Repair`, undefined, accessToken);
}
