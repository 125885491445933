import React, { useState } from 'react';
import { AuthorizedPage } from 'auth/AuthorizedPage';
import { Box, Divider } from '@mui/material';
import { Page } from 'components/Page';
import useMemoTranslation from '../hooks/UseMemoTranslation';
import { PageContent } from '../components/PageContent';
import { PipelineRules } from './components/PipelineRules';
import { PipelineRulesGrid } from './components/PipelineRulesGrid';
import { FlexBox } from '../components/FlexBox';
import { FlexTableBox } from '../components/FlexTableBox';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { ErrorIndicator } from '../components/ErrorIndicator';
import { LoadingState } from '../components/LoadingStateUtil';
import { PipelineRulesContextProvider } from '../contexts/PipelineRulesContext';
import { SuperDuperFiestaEntity, SuperDuperFiestaPipeline, SuperDuperFiestaStage } from 'data/SuperDuperFiestaData';

export const PipelineRulesPage = () => {
  const { t } = useMemoTranslation();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [selectedEntity, setSelectedEntity] = useState<SuperDuperFiestaEntity | null>(null);
  const [selectedPipeline, setSelectedPipeline] = useState<SuperDuperFiestaPipeline | null>(null);
  const [selectedStage, setSelectedStage] = useState<SuperDuperFiestaStage | null>(null);

  return (
    <AuthorizedPage>
      <Page title={t('pipelineRules')}>
        <PipelineRulesContextProvider>
          <PageContent>
            <FlexBox sx={{ flexDirection: 'column', height: '100%' }}>
              <FlexBox sx={{ flexDirection: 'row', gap: 3, flexGrow: 2, height: '100%' }}>
                <Box sx={{ width: '25%' }}>
                  <PipelineRules
                    loadingProps={[loadingState, setLoadingState]}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    selectedPipeline={selectedPipeline}
                    setSelectedPipeline={setSelectedPipeline}
                    selectedStage={selectedStage}
                    setSelectedStage={setSelectedStage}
                  />
                </Box>
                <Divider orientation={'vertical'} />
                <FlexTableBox sx={{ width: '100%' }}>
                  <PipelineRulesGrid
                    loadingProps={[loadingState, setLoadingState]}
                    selectedEntity={selectedEntity}
                    selectedPipeline={selectedPipeline}
                    selectedStage={selectedStage}
                  />
                </FlexTableBox>
              </FlexBox>
              <Box>
                <LoadingIndicator loadingState={loadingState} margin={'T'} />
                <ErrorIndicator loadingState={loadingState} />
              </Box>
            </FlexBox>
          </PageContent>
        </PipelineRulesContextProvider>
      </Page>
    </AuthorizedPage>
  );
};
