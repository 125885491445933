import { useTheme } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-pro';
import { FlexTableBox } from 'components/FlexTableBox';
import { LoadingState } from '../LoadingStateUtil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getEmptyGridText } from './GridLoading';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { debounce, filter } from 'lodash';
import { getModifiedGridStringOperators } from '../../util/grid/GridOperatorUtils';

export interface CompactGridWrapperProps extends DataGridProProps {
  flexGrow?: number;
  columnsToAutoSize?: string[];
  loadingState?: LoadingState;
  hideLoadingIndicator?: boolean;
}

export const CompactGridWrapper = (props: CompactGridWrapperProps) => {
  const { columns, rows, ...otherProps } = props;

  const theme = useTheme();
  const cellFontColor = theme.colors.alpha.black[100]; //#223354

  const [refreshedColumnSizes, setRefreshedColumnSizes] = useState<boolean>(false);

  const modifiedColumns = useMemo(() => {
    const modifiedGridStringOperators = getModifiedGridStringOperators();

    return columns.map(column => {
      const columnType = column.type ?? 'string';
      if (columnType === 'string') {
        return {
          ...column,
          filterOperators: column.filterOperators || modifiedGridStringOperators,
        };
      }
      return column;
    }) as GridColDef[];
  }, [columns]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceColumnAutoSize = useCallback(
    debounce(() => {
      if (!props.apiRef?.current || !props.columnsToAutoSize || props.columnsToAutoSize.length === 0) {
        return;
      }

      const columnsToAutoSize = filter(
        props.columnsToAutoSize ?? [],
        column => column !== GRID_CHECKBOX_SELECTION_FIELD
      );

      props.apiRef.current
        .autosizeColumns({
          includeHeaders: true,
          includeOutliers: false,
          expand: true,
          columns: columnsToAutoSize,
        })
        .then(_ => setRefreshedColumnSizes(props.rows.length > 0));
    }, 1000),
    [props.apiRef, props.columnsToAutoSize]
  );

  useEffect(() => {
    if (!props.apiRef || !props.columnsToAutoSize || props.columnsToAutoSize.length === 0) {
      return;
    }

    if (refreshedColumnSizes) {
      return;
    }

    if (props.rows === undefined || props.rows.length === 0) {
      return;
    }

    const resizeDebounce = debounceColumnAutoSize;
    resizeDebounce();

    return () => resizeDebounce?.cancel();
  }, [props.apiRef, props.columns, props.rows, props.columnsToAutoSize, refreshedColumnSizes, debounceColumnAutoSize]);

  // If the columns changes then reset de-duper state
  useEffect(
    () => {
      if (!props.apiRef || !props.columnsToAutoSize || props.columnsToAutoSize.length === 0) {
        return;
      }

      setRefreshedColumnSizes(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.apiRef, props.columns, props.columnsToAutoSize, props.columnVisibilityModel]
  );

  return (
    <FlexTableBox
      flexGrow={props.flexGrow}
      sx={{
        width: '100%',
        fontSize: 12,
        overflow: 'hidden',
        '& .compact-grid--header, .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'break-spaces',
          lineHeight: 1.2,
          display: 'flex',
          textAlign: 'center',
        },
        '& .livinglab-grid--cell': {
          backgroundColor: 'rgba(224, 183, 60, 0.55)',
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.pass': {
          backgroundColor: theme.colors.success.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.warn': {
          backgroundColor: theme.colors.warning.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .livinglab-grid.fail': {
          backgroundColor: theme.colors.error.light,
          color: cellFontColor,
          fontWeight: '400',
        },
        '& .MuiDataGrid-footerContainer': {
          height: '3vh',
          minHeight: '3vh',
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'visible !important',
        },
        '& .monospace-font': {
          fontFamily: 'monospace',
        },
      }}
    >
      <DataGridPro
        columns={modifiedColumns}
        rows={rows}
        {...otherProps}
        sx={{
          ...(props.sx ?? {}),
          fontSize: 12,
        }}
        density={'compact'}
        localeText={{
          noRowsLabel: getEmptyGridText(props.rows.length, props.loadingState),
          noResultsOverlayLabel: getEmptyGridText(props.rows.length, props.loadingState),
          // this lets us override the text shown in the pagination footer
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => {
              if (count !== Number.MAX_VALUE) {
                return `${from} - ${to} of ${count}`;
              }
              return `${from} - ${to} of many`;
            },
          },
        }}
      />
      {props.loadingState && props.hideLoadingIndicator !== true && (
        <>
          <LoadingIndicator type={'Linear'} loadingState={props.loadingState} margin={'All'} />
          <ErrorIndicator loadingState={props.loadingState} />
        </>
      )}
    </FlexTableBox>
  );
};
