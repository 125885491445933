import React, { createContext, useCallback, useReducer, useState } from 'react';
import useAuth from 'auth/UseAuth';
import _ from 'lodash';
import {
  GetPipelineFilters,
  PipelineFilters,
  PipelineFiltersConfig,
  SdfIssue,
  SdfStepType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from '../data/SuperDuperFiestaData';

interface IPipelineFiltersState {
  dataClasses: SuperDuperFiestaDataClass[];
  dataSources: SuperDuperFiestaDataSource[];
  entities: SuperDuperFiestaEntity[];
  issues: SdfIssue[];
  pipelines: SuperDuperFiestaPipeline[];
  stages: SuperDuperFiestaStage[];
  stepTypes: SdfStepType[];
}

interface IPipelineFiltersAction {
  type:
    | 'update_pipelines'
    | 'update_stages'
    | 'update_entities'
    | 'update_stepTypes'
    | 'update_issues'
    | 'update_dataClasses'
    | 'update_dataSources'
    | 'update_all'
    | 'reset';
  dataClasses?: SuperDuperFiestaDataClass[];
  dataSources?: SuperDuperFiestaDataSource[];
  entities?: SuperDuperFiestaEntity[];
  issues?: SdfIssue[];
  pipelines?: SuperDuperFiestaPipeline[];
  stages?: SuperDuperFiestaStage[];
  stepTypes?: SdfStepType[];
}

const initialFiltersState = {
  pipelines: [],
  stages: [],
  entities: [],
  stepTypes: [],
  issues: [],
  dataClasses: [],
  dataSources: [],
};

export interface IUpdateValueProps {
  id: string;
  stepUpdateValueId: string | undefined;
  field: string | undefined;
  value: string | undefined;
  removed: boolean;
}

export const defaultUpdateValue = {
  stepUpdateValueId: undefined,
  field: '',
  value: '',
  removed: false,
};

export interface IRuleFormInput {
  executionOrder: number | null;
  ruleName: string;
  description: string;
  queryCondition: string;
  queryJoin: string;
  queryGroupBy: string;
  needsReview: boolean;
  active: boolean;
  stepUpdateValues: IUpdateValueProps[] | null;
  pipeline: SuperDuperFiestaPipeline | null;
  stepType: SdfStepType | null;
  entity: SuperDuperFiestaEntity | null;
  stage: SuperDuperFiestaStage | null;
  dataClass: SuperDuperFiestaDataClass | null;
  dataSources: SuperDuperFiestaDataSource[] | null;
  issue: SdfIssue | null;
}

export const defaultFormValues = {
  executionOrder: null,
  ruleName: '',
  description: '',
  queryCondition: '',
  queryJoin: '',
  queryGroupBy: '',
  needsReview: false,
  active: true,
  stepUpdateValues: [defaultUpdateValue],
  pipeline: null,
  stepType: null,
  entity: null,
  stage: null,
  dataSource: null,
  dataClass: null,
  issue: null,
};

function pipelineFiltersReducer(state: IPipelineFiltersState, action: IPipelineFiltersAction): IPipelineFiltersState {
  const { type } = action;

  switch (type) {
    case 'update_pipelines': {
      return { ...state, pipelines: action.pipelines ?? [] };
    }
    case 'update_stages': {
      return { ...state, stages: action.stages ?? [] };
    }
    case 'update_entities': {
      return { ...state, entities: action.entities ?? [] };
    }
    case 'update_stepTypes': {
      return { ...state, stepTypes: action.stepTypes ?? [] };
    }
    case 'update_issues': {
      return { ...state, issues: action.issues ?? [] };
    }
    case 'update_dataClasses': {
      return { ...state, dataClasses: action.dataClasses ?? [] };
    }
    case 'update_dataSources': {
      return { ...state, dataSources: action.dataSources ?? [] };
    }
    case 'update_all': {
      return { ...state, ...action };
    }
    case 'reset': {
      return { ...initialFiltersState };
    }
    default:
      return state;
  }
}

export interface IPipelineRulesContextData {
  pipelineFiltersState: IPipelineFiltersState;
  refreshData: boolean;
}

export interface IPipelineRulesContext extends IPipelineRulesContextData {
  getFilterData: () => void;
  pipelineFiltersDispatch: React.Dispatch<IPipelineFiltersAction>;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
}

const PipelineRulesContext = createContext<IPipelineRulesContext>({
  getFilterData: () => {
    /* empty */
  },
  pipelineFiltersState: initialFiltersState,
  pipelineFiltersDispatch: () => {
    /* empty */
  },
  refreshData: false,
  setRefreshData: () => {
    /* empty */
  },
});

export const PipelineRulesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { accessToken } = useAuth();

  const [pipelineFiltersState, pipelineFiltersDispatch] = useReducer(pipelineFiltersReducer, initialFiltersState);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const getFilterData = useCallback(async () => {
    if (!accessToken) {
      return;
    }

    const queryParams: PipelineFiltersConfig = {
      includePipelines: pipelineFiltersState.pipelines.length === 0,
      includeStages: pipelineFiltersState.stages.length === 0,
      includeEntities: pipelineFiltersState.entities.length === 0,
      includeStepTypes: pipelineFiltersState.stepTypes.length === 0,
      includeIssues: pipelineFiltersState.issues.length === 0,
      includeDataClasses: pipelineFiltersState.dataClasses.length === 0,
      includeDataSources: pipelineFiltersState.dataSources.length === 0,
    };

    // Prevent request if all filters are already populated
    const values = _.uniq(Object.values(queryParams));
    if (values.length === 1 && values[0] === false) {
      return;
    }

    const data: PipelineFilters = await GetPipelineFilters(queryParams, accessToken);

    if (!data) {
      return;
    }

    pipelineFiltersDispatch({ type: `update_all`, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <PipelineRulesContext.Provider
      value={{
        getFilterData,
        pipelineFiltersState,
        pipelineFiltersDispatch,
        refreshData,
        setRefreshData,
      }}
    >
      {children}
    </PipelineRulesContext.Provider>
  );
};

export default PipelineRulesContext;
