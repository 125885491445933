import Highcharts from 'highcharts';

export const computeNewTickInterval = (chart: Highcharts.Chart, labelWidthPx: number): number | null => {
  const xAxis = chart.xAxis[0];
  const xAxisWidthPx = (xAxis as any).len as number;
  const xAxisSize = (xAxis.max ?? 0) - (xAxis.min ?? 0);

  const singleValueWidthPx = xAxisWidthPx / xAxisSize;

  const numValuesInLabelWidth = Math.ceil(labelWidthPx / singleValueWidthPx);

  return numValuesInLabelWidth !== xAxis.options.tickInterval ? numValuesInLabelWidth : null;
};
