import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip } from '@mui/material';
import useAuth from 'auth/UseAuth';
import { CancelButton } from 'components/CancelButton';
import { DeleteButton } from 'components/DeleteButton';
import { FlexBox } from 'components/FlexBox';
import { PrimaryButton } from 'components/PrimaryButton';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { DialogOpenButton } from '../../../components/DialogOpenButton';
import { ErrorIndicator } from '../../../components/ErrorIndicator';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ErrorManagement, LoadingState } from '../../../components/LoadingStateUtil';
import PipelineRulesContext, { IRuleFormInput, defaultFormValues } from '../../../contexts/PipelineRulesContext';
import {
  CreatePipelineRules,
  DeletePipelineRule,
  PipelineRule,
  PipelineRulesConfig,
  SdfStepUpdateValue,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
  UpdatePipelineRule,
} from '../../../data/SuperDuperFiestaData';
import { PipelineRulesModalContent } from './PipelineRulesModalContent';
import { PipelineRulesModalGrid } from './PipelineRulesModalGrid';

export const PipelineRulesModal = ({
  selectedEntity,
  selectedPipeline,
  selectedStage,
  canOpen,
  open,
  setOpen,
  allRows = [],
  selectedRow,
  setSelectedRow,
}: {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
  canOpen: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allRows: PipelineRule[];
  selectedRow: PipelineRule | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<PipelineRule | null>>;
}) => {
  const { accessToken, user } = useAuth();
  const { getFilterData, pipelineFiltersState, setRefreshData } = useContext(PipelineRulesContext);
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    trigger,
    formState: { isDirty, isValid },
  } = useForm<IRuleFormInput>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [rows, setRows] = useState<PipelineRule[]>([]);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [newStepIds, setNewStepIds] = useState<string[]>([]);
  const [editedStepId, setEditedStepId] = useState<string | null>(null);

  const [selectedDataSources, setSelectedDataSources] = useState<SuperDuperFiestaDataSource[]>([]);

  useEffect(() => {
    setIsNew(selectedRow === null && editedStepId === null);
  }, [selectedRow, editedStepId, setIsNew]);

  useEffect(() => {
    if (open && selectedRow !== null) {
      const editRule = {
        id: selectedRow.id,
        executionOrder: selectedRow.executionOrderDisplay,
        ruleName: selectedRow.name,
        description: selectedRow.description || '',
        queryCondition: selectedRow.queryCondition || '',
        queryJoin: selectedRow.queryJoin || '',
        queryGroupBy: selectedRow.queryGroupBy || '',
        needsReview: selectedRow.needsReview,
        active: selectedRow.active || true,
        pipeline: selectedPipeline,
        entity: selectedEntity,
        stage: selectedStage,
        stepUpdateValues: selectedRow.stepUpdateValues,
        stepType: pipelineFiltersState.stepTypes?.find(st => st.displayName === selectedRow.stepTypeName),
        dataClass: pipelineFiltersState.dataClasses?.find(dc => dc.name === selectedRow.dataClassName),
        issue: pipelineFiltersState.issues?.find(dc => dc.name === selectedRow.issueName),
      };

      const dataSource = pipelineFiltersState.dataSources?.find(ds => ds.displayName === selectedRow.dataSourceName);

      setSelectedDataSources(!dataSource ? [] : [dataSource]);
      reset(editRule);
    }
  }, [selectedRow, open, pipelineFiltersState, reset, selectedEntity, selectedPipeline, selectedStage]);

  useEffect(() => {
    if (!open) {
      return;
    }

    // Filter out grouped rows
    const filteredRows = allRows.filter(r => r.hierarchy && r.hierarchy?.length <= 1);
    // Update IDs and execution order to reflect change
    filteredRows.forEach((row, index) => {
      row.id = index;
      row.executionOrder = index + 1;
      row.executionOrderDisplay = index + 1;
    });
    setRows(filteredRows);
  }, [allRows, open]);

  const handleOpen = async () => {
    setOpen(true);
    getFilterData();
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);
  };

  const handleAddRule = async (data: IRuleFormInput) => {
    let newRows: PipelineRule[] = [];
    const newRowsParams = buildRule(data);

    if (newRowsParams === undefined) {
      return;
    }

    if (selectedDataSources.length > 0 && selectedDataSources.length < pipelineFiltersState.dataSources?.length) {
      selectedDataSources.forEach((ds, idx) => {
        newRows.push({
          ...newRowsParams,
          id: newRowsParams.id + idx,
          executionOrder: newRowsParams.executionOrder + idx,
          executionOrderDisplay: newRowsParams.executionOrder + idx,
          dataSourceName: ds.displayName,
          dataSourceId: ds.dataSourceId,
        });
      });
    } else {
      newRows.push({
        ...newRowsParams,
        dataSourceName: null,
        dataSourceId: null,
      });
    }

    const orderedRows = updateRows(newRows);
    const newRowStepIds = newRows.map(r => r.stepId);

    setNewStepIds([...newStepIds, ...newRowStepIds].filter(r => r !== null));
    setRows(orderedRows);
    clearForm();
  };

  const handleUpdateRule = async (data: IRuleFormInput) => {
    if (selectedRow === null || selectedRow.id === undefined) {
      return;
    }
    const updatedRowsParams = buildRule(data);

    if (updatedRowsParams === undefined) {
      return;
    }

    const updatedRule = { ...selectedRow, ...updatedRowsParams };
    const currentIndex = rows.findIndex(r => r.id === selectedRow.id);
    const orderedRows = updateRows([updatedRule], currentIndex);

    // Handle updating a new rule
    if (newStepIds.length > 0 && newStepIds.includes(updatedRowsParams.stepId)) {
      setSelectedRow(null);
    } else {
      setEditedStepId(updatedRowsParams.stepId);
    }
    setRows(orderedRows);
    clearForm();
  };

  const handleSave = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedPipeline !== null && selectedStage !== null && newStepIds.length > 0) {
        const newRules = rows.filter(r => newStepIds.includes(r.stepId));
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
        };
        await CreatePipelineRules(newRules, queryParams, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  };

  const handleUpdate = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      const editedRule = rows.find(r => r.stepId === editedStepId);
      if (selectedPipeline !== null && selectedStage !== null && editedStepId !== null && editedRule !== undefined) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
        };
        await UpdatePipelineRule(editedRule.stepId, editedRule, queryParams, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  };

  const handleDelete = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (selectedRow !== null && selectedRow.stepId !== null) {
        await DeletePipelineRule(selectedRow.stepId, accessToken);
        handleClose();
        setRefreshData(true);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
    setRows([]);
    setNewStepIds([]);
    setEditedStepId(null);
    setLoadingState({ status: 'NotStarted' });
  };

  const clearForm = () => {
    reset(defaultFormValues);
    setSelectedDataSources([]);
    setSelectedRow(null);
    setValue('pipeline', selectedPipeline);
    setValue('entity', selectedEntity);
    setValue('stage', selectedStage);
  };

  const removeRow = (stepId: string) => {
    // remove row from grid
    const newRows = removeFromList(stepId, rows);
    if (newRows) {
      setRows(newRows);
    }

    // remove new rule
    if (newStepIds.length > 0) {
      const updatedStepIds = newStepIds.filter(r => r !== stepId);
      setNewStepIds(updatedStepIds);
    }
  };

  const editRow = (stepId: string) => {
    const selectedRule = rows.find(r => r.stepId === stepId);
    if (selectedRule) {
      setSelectedRow(selectedRule);
    }
  };

  function removeFromList(stepId: string, rowList: PipelineRule[]) {
    const rowIndex = rowList.findIndex(r => r.stepId === stepId);
    if (rowIndex === -1) {
      return;
    }

    let tempRows = [...rowList];
    // remove row from existing index
    tempRows.splice(rowIndex, 1);
    // update list
    tempRows.forEach((row, index) => {
      row.id = index;
      row.executionOrder = index + 1;
      row.executionOrderDisplay = index + 1;
    });
    return tempRows;
  }

  function buildRule(data: IRuleFormInput): PipelineRule | undefined {
    if (data.entity === null) {
      return;
    }

    const executionOrder = Number(data.executionOrder ? data.executionOrder : rows.length + 1);
    const suvs: SdfStepUpdateValue[] = [];

    data.stepUpdateValues?.forEach(suv => {
      if (suv.field !== '' && suv.value !== '') {
        suvs.push(suv);
      }
    });

    return {
      id: executionOrder - 1,
      stepId: selectedRow?.stepId || uuid(),
      name: data.ruleName,
      description: data.description,
      stepTypeName: data.stepType?.displayName || null,
      stepTypeId: data.stepType?.stepTypeId || null,
      entityName: data.entity.displayName,
      entityId: data.entity.entityId,
      dataClassName: data.dataClass?.name,
      dataClassId: data.dataClass?.dataClassId,
      dataSourceName: selectedDataSources[0]?.displayName,
      dataSourceId: selectedDataSources[0]?.dataSourceId,
      issueName: data.issue?.name,
      issueId: data.issue?.issueId,
      needsReview: data.needsReview,
      queryCondition: data.queryCondition,
      queryJoin: data.queryJoin,
      queryGroupBy: data.queryGroupBy,
      stepUpdateValues: suvs,
      active: data.active,
      createdBy: selectedRow?.createdBy || user?.name,
      updatedBy: isNew ? null : user?.name,
      stageId: selectedStage?.stageId,
      pipelineId: selectedPipeline?.pipelineId,
      executionOrderDisplay: executionOrder,
      executionOrder: executionOrder,
    };
  }

  function updateRows(newRows: PipelineRule[], currentIndex: number | null = null) {
    let newOrderedRows = [...rows];

    newRows.forEach(r => {
      const tempRows = [...newOrderedRows];

      if (currentIndex != null) {
        // remove row from existing index
        tempRows.splice(currentIndex, 1);
      }
      r.previousStepId = tempRows[Number(r.id) - 1]?.stepId;
      // add row at index
      newOrderedRows = [...tempRows.slice(0, r.executionOrder - 1), r, ...tempRows.slice(r.executionOrder - 1)];
    });

    // update all row id's and order #'s
    newOrderedRows.forEach((row, index) => {
      row.id = index;
      row.executionOrder = index + 1;
      row.executionOrderDisplay = index + 1;
    });

    return newOrderedRows;
  }

  return (
    <>
      <Tooltip title={'All required filters must be selected'} disableHoverListener={canOpen}>
        <span>
          <DialogOpenButton title={'Create Rule'} onClick={handleOpen} disabled={!canOpen} />
        </span>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xl'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {isNew ? 'Create New Rule(s)' : 'Update Rule'}
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, height: '100vh', mt: 1 }}>
            <PipelineRulesModalContent
              selectedEntity={selectedEntity}
              selectedDataSources={selectedDataSources}
              setSelectedDataSources={setSelectedDataSources}
              control={control}
              trigger={trigger}
              watch={watch}
              setValue={setValue}
              isNew={isNew}
            />
            <FlexBox flexDirection={'row-reverse'}>
              <DialogOpenButton
                title={isNew ? 'Add Rule' : 'Update Rule'}
                onClick={handleSubmit(isNew ? handleAddRule : handleUpdateRule)}
                disabled={!isDirty || !isValid}
              />
            </FlexBox>
            <Divider sx={{ mb: 2, background: 'lightgray' }} />
            <PipelineRulesModalGrid
              rows={rows}
              setRows={setRows}
              interactableRows={rows.filter(r =>
                [...newStepIds, editedStepId].filter(id => id !== null).includes(r.stepId)
              )}
              removeRow={removeRow}
              editRow={editRow}
              newStepIds={newStepIds}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'} />
          <DeleteButton onClick={handleDelete} disabled={loadingState.status === 'Loading'} hidden={isNew} />
          <PrimaryButton
            onClick={isNew ? handleSave : handleUpdate}
            disabled={loadingState.status === 'Loading' || (newStepIds.length === 0 && editedStepId === null)}
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
